@import url(https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap);
.preloader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgb(49, 49, 177);
    background-position: center center;
    background-repeat: no-repeat;
    opacity: 1;
    transition: 1s opacity;
  }
  .preloader.loaded {
    opacity: 0;
    visibility: hidden;
  }
  .preloader .spinner_wrap {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    z-index: 33333;
  }
  .preloader .spinner {
    width: 50px;
    height: 50px;
    background-color: rgba(255, 255, 255, 0.8);
    -webkit-animation: sk-rotateplane 1.2s infinite ease-in-out;
    animation: sk-rotateplane 1.2s infinite ease-in-out;
  }
  
  @-webkit-keyframes sk-rotateplane {
    0% {
      -webkit-transform: perspective(120px);
    }
    50% {
      -webkit-transform: perspective(120px) rotateY(180deg);
    }
    100% {
      -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg);
    }
  }
  @keyframes sk-rotateplane {
    0% {
      transform: perspective(120px) rotateX(0deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
      transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
  }
  
#countdown {
  color: #fff;
  width: 70vw;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  display: inline-block;
  text-align: center;
}
#countdown .box {
  padding: 10px;
  border-right: solid 1px rgba(255, 255, 255, 0.2);
}
#countdown .col-4:last-child .box {
  border-right-color: transparent;
}
#countdown .box p {
  font-size: 50px;
  font-weight: bold;
  margin: 0;
}
#countdown .box .text {
  font-size: 12px;
  font-family: sans-serif;
}

@media (min-width: 768px) {
  .container {
    width: 1100px;
  }
  h1 {
    font-size: 70px;
  }
  #countdown {
    width: 40vw;
    padding: 20px;
  }
}

.App {
  background-image: url(https://image.freepik.com/free-vector/round-podium-stage-spotlights-rays-realistic_107791-448.jpg);
  color: rgb(255, 255, 255);
  font-family: 'Roboto Condensed', sans-serif;
  background-size: cover;
  margin: 0;
  background-position: bottom;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: rgba(49, 151, 177, 0.534);
  background: linear-gradient(
    0deg,
    rgba(49, 49, 177, 0.356) 0%,
    rgba(49, 49, 177, 1) 50%,
    rgb(49, 49, 177) 100%
  );
  width: 100%;
  height: 100vh;
}

.col-4 {
  width: 25%;
  float: left;
}

h1 {
  font-size: 30px;
  text-transform: uppercase;
  line-height: 1;
}

@media (min-width: 768px) {
  .container {
    width: 100%;
  }
  h1 {
    font-size: 58px;
  }
}

