@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed&display=swap');

.App {
  background-image: url(https://image.freepik.com/free-vector/round-podium-stage-spotlights-rays-realistic_107791-448.jpg);
  color: rgb(255, 255, 255);
  font-family: 'Roboto Condensed', sans-serif;
  background-size: cover;
  margin: 0;
  background-position: bottom;
  background-repeat: no-repeat;
  height: 100vh;
  width: 100%;
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  background: rgba(49, 151, 177, 0.534);
  background: linear-gradient(
    0deg,
    rgba(49, 49, 177, 0.356) 0%,
    rgba(49, 49, 177, 1) 50%,
    rgb(49, 49, 177) 100%
  );
  width: 100%;
  height: 100vh;
}

.col-4 {
  width: 25%;
  float: left;
}

h1 {
  font-size: 30px;
  text-transform: uppercase;
  line-height: 1;
}

@media (min-width: 768px) {
  .container {
    width: 100%;
  }
  h1 {
    font-size: 58px;
  }
}
